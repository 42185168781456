import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { SocialIcon } from "react-social-icons";

export const SocialMediaLinks = () => {
  return (
    <HStack p="25px 0px 10px 0px" spacing="24px">
      <Text fontSize="2xl">Follow us on:</Text>
      <SocialIcon
        url="https://www.facebook.com/profile.php?id=100094675665938"
        target="_blank"
        network="facebook"
      />
      <SocialIcon
        network="instagram"
        url="https://www.instagram.com/maakikripaindia"
        target="_blank"
      />
    </HStack>
  );
};
