import React from "react";
import { HStack, Box, VStack, Img } from "@chakra-ui/react";
import CountUp from "react-countup";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import product from "../assets/product.png";
import customer from "../assets/customer.png";
import cities from "../assets/cities.png";
import brands from "../assets/brands.png";

export const Impact = (props) => {
  const { title, count } = props;
  const { isDesktop } = useCalculatedBreakpoints();

  //TODO: Add a switch case for the title and return the respective image
  const getCardImage = (title) => {
    switch (title) {
      case "Cities":
        return <Img style={{ height: "100px", width: "100px" }} boxSize={isDesktop ? "150px" : "100px"} src={cities} />;
      case "Products":
        return <Img style={{ height: "100px", width: "100px" }} boxSize={isDesktop ? "150px" : "100px"} src={product} />;
      case "Brands":
        return <Img style={{ height: "100px", width: "100px" }} boxSize={isDesktop ? "150px" : "100px"} src={brands} />;
      case "Customers":
        return <Img style={{ height: "100px", width: "100px" }} boxSize={isDesktop ? "150px" : "100px"} src={customer} />;
      default:
        return
    }
  };

  const getSuffix = (title) => { 
    return `+ ${title}`
  }

  return (
    <Box w="full" bgColor="red.50" flexBasis="10%">
      <HStack
        w="full"
        justifyContent={isDesktop ? "space-evenly" : "flex-start"}
        p={isDesktop ? "0px" : "0px 45px"}
      >
        {getCardImage(title)}
        {isDesktop ? (
          <VStack align="flex-end" color="black" gap="-2" fontSize="x-large">
              <CountUp
                enableScrollSpy
                scrollSpyDelay={1}
                end={count}
                delay={5}
                suffix={getSuffix(title)}
              />
          </VStack>
        ) : (
            <HStack p="10px" fontSize="xl">
              <CountUp
                enableScrollSpy
                scrollSpyDelay={1}
                end={count}
                delay={5}
                suffix={getSuffix(title)}
              />
            </HStack>
        )}
      </HStack>
    </Box>
  );
};
