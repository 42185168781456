import React from "react";
import { VStack, Text } from "@chakra-ui/react";

export const Location = (props) => {
  const { location } = props;
  return (
    <VStack w="full" align="left" flexBasis="10%">
      <Text color="red.700" fontSize="x-large" fontWeight="bold">{location.title}</Text>
        <Text fontSize="large">{location.address}</Text>
    </VStack>
  );
};
