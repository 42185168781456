import React from "react";
import { Navbar } from "./Navbar";
import { VStack, Text } from "@chakra-ui/react";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";

export const Offers = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  return (
    <VStack>
      <Navbar currentIndex={3} />
      <VStack
        display="flex"
        width="full"
        marginTop="100px"
        p={isDesktop ? "0px 25px 0px 25px" : "0px 9px 0px 9px"}
        h="74vh"
      >
      <Text
          w="full"
          align="center"
          fontSize="xxx-large"
          fontWeight="bold"
          color="red.700"
        >
          Offers
        </Text>
        <Text w="full" align="start" fontSize="large">Launching soon ...</Text>
      </ VStack>
    </VStack>
  );
};
