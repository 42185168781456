import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Box, Img } from "@chakra-ui/react";
import image1 from "../assets/crousal-images/image1.png";
import image2 from "../assets/crousal-images/image2.png";
import image3 from "../assets/crousal-images/image3.png";
import image4 from "../assets/crousal-images/image4.png";
import image5 from "../assets/crousal-images/image5.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";

export const CarouselCustom = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  return (
    <Box p="0px" max>
      <Carousel
        showStatus={false}
        showIndicators
        showThumbs={false}
        useKeyboardArrows
        infiniteLoop
        showArrows={false}
        autoPlay
        interval={3000}
        stopOnHover={false}
        dynamicHeight={false}
      >
        <Box style={isDesktop ? { height: "85vh" } : null }>
          <Img src={image1} alt="maa kri kripa 1" />
        </Box>
        <Box style={isDesktop ? { height: "85vh" } : null }>
          <Img src={image3} alt="maa kri kripa  2" />
        </Box>
        <Box style={isDesktop ? { height: "85vh" } : null }>
          <Img src={image2} alt="maa kri kripa 3" />
        </Box>
        <Box style={isDesktop ? { height: "85vh" } : null }>
          <Img src={image4} alt="maa kri kripa 4" />
        </Box>
        <Box style={isDesktop ? { height: "85vh" } : null }>
          <Img src={image5} alt="maa kri kripa 5" />
        </Box>
      </Carousel>
    </Box>
  );
};
