import React from "react";
import { useNavigate } from "react-router-dom";
import { NavBarTabs } from "./NavBarTabs";
import { Box, HStack } from "@chakra-ui/react";
import mkkLogo from "../assets/mkkLogo.png";
import mkkText from "../assets/mkkText.png";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import { Hamburger } from "./Hamburger";

export const Navbar = (props) => {
  const currentIndex = props.currentIndex;
  const navigate = useNavigate();
  const handleTabClick = (tabIndex) => {
    navigate(
      tabIndex === 0
        ? "/"
        : `/${
            tabIndex === 1
              ? "about-us"
              : tabIndex === 2
              ? "products"
              : tabIndex === 3
              ? "offers"
              : "contact-us"
          }`
    );
  };

  const navBarTabs = [
    { label: "Home" },
    { label: "About Us" },
    { label: "Our Products" },
    { label: "Offers" },
    { label: "Contact Us" },
  ];

  const { isDesktop } = useCalculatedBreakpoints();

  return (
    <Box
      bgColor="red.50"
      w="full"
      zIndex={1}
      style={{
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
        borderBottomColor: "black",
      }}
    >
      <HStack flexDirection="row" justifyContent="space-between" h="90px">
        <HStack h="90px">
          <img src={mkkLogo} height="100px" width="100px" alt="logo" />
          {isDesktop && (
            <img src={mkkText} height="100px" width="400px" alt="logo" />
          )}
        </HStack>
        {isDesktop ? (
          <NavBarTabs
            currentIndex={currentIndex}
            navBarTabs={navBarTabs}
            handleTabClick={handleTabClick}
          />
        ) : (
          <Hamburger
            currentIndex={currentIndex}
            menuItems={navBarTabs}
            handleMenuItemClick={handleTabClick}
          />
        )}
      </HStack>
    </Box>
  );
};
