import React from "react";
import { Box, Stack, VStack, Img} from "@chakra-ui/react";
import { Impact } from "./Impact";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import impact from '../assets/Impact.png';

export const OurStory = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  const impacts = [
    { title: "Cities", count: 3},
    { title: "Products", count: 1500},
    { title: "Brands", count: 500},
    { title: "Customers", count: 700},
  ];
  return (
    <Box w="full">
      <VStack w="full">
        <Img id="maa-ki-kripa-story-img" src={impact} alt="impact" w={isDesktop ? "50vh" : "40vh"} h={isDesktop ? "200px" : "120px"} />
        {/* <Text fontSize="4xl" fontWeight="bold" textAlign="center" marginTop="25px" fontFamily="fantasy">JOURNEY SO FAR</Text> */}
        <Stack id="maa-ki-kripa-impact" flexDirection={isDesktop ? "row" : "column"} w="full" flexWrap="wrap" justifyContent="space-evenly">
          {impacts.map((impact, index) => {
            return <Impact key={index} title={impact.title} count={impact.count} />
        })}
        </Stack>
      </VStack>
    </Box>
  );
};
