import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import { CopyRights } from "./CopyRights";
import { SocialMediaLinks } from "./SocialMediaLinks";

export const Footer = () => {
  return (
    <Box w="full" h="full">
      <VStack display="flex">
        <SocialMediaLinks />
        <CopyRights />
      </VStack>
    </Box>
  );
};
