import React from "react";
import { Navbar } from "./Navbar";
import { VStack } from "@chakra-ui/react";
import { CarouselCustom } from "./CarouselCustom";
import { Vision } from "./Vision";
import { OurStory } from "./OurStory";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";

export const Home = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  return (
    <VStack id="maa-ki-kripa-sales" display="flex">
      <Navbar id="maa-ki-kripa-navbar" currentIndex={0} />
      <VStack overflow="hidden" display="flex" width="full" marginTop="10vh" p={isDesktop ? "0px 25px 0px 25px" : "0px 9px 0px 9px"}>
        <CarouselCustom id="maa-ki-kripa-images" />
        <Vision id="maa-ki-kripa-vision"/>
        <OurStory id="maa-ki-kripa-story" />
      </VStack>
    </VStack>
  );
};
