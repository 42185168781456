import React from "react";
import { Navbar } from "./Navbar";
import { VStack, Text, Stack, Img, Box } from "@chakra-ui/react";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import chairman from "../assets/chairman.png";
import journey from "../assets/journey.png";

export const About = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  return (
    <VStack>
      <Navbar currentIndex={1} />
      <VStack
        display="flex"
        width="full"
        marginTop="100px"
        p={isDesktop ? "0px 25px 0px 25px" : "0px 9px 0px 9px"}
      >
        <Text
          w="full"
          align="center"
          fontSize="xxx-large"
          fontWeight="bold"
          color="red.700"
        >
          Overview
        </Text>
        <Text w="full" align="start" fontSize="large">
          Welcome to Maa Ki Kripa Sales, where our unwavering dedication revolves around
          uniting buyers and suppliers nationwide. MKK, representing Maa Ki
          Kripa, reflects our faith in a higher power. With over 35 years in the
          garment industry, we actively collaborate with customers, ensuring
          access to the right products at the right prices, and facilitating
          seamless deliveries. As we contribute to expanding the garment buying
          industry, simplifying stakeholder lives, fostering business
          connections, and ensuring community growth, we warmly invite you to
          join us on this unique journey of inspiration and prosperity. Welcome
          to a partnership with open hands and hearts at Maa Ki Kripa Sales.
        </Text>
        <Text
          w="full"
          align="start"
          fontSize="xx-large"
          fontWeight="bold"
          color="red.700"
        >
          Our Story
        </Text>
        <Img
          style={
            isDesktop
              ? { height: "60vh", width: "70vw" }
              : { height: "25vh", width: "90vw" }
          }
          src={journey}
        />
        <Text
          w="full"
          align="start"
          fontSize="xx-large"
          fontWeight="bold"
          color="red.700"
        >
          Chairman's Message
        </Text>
        <Stack p="25px" flexDirection={isDesktop ? "row" : "column"} justifyContent="space-around">
          <Box paddingLeft={isDesktop ? "0px" : "50px"}>
          <Img borderRadius="150px" style={{ height:"200px", width:"200px"}} src={chairman} />
          </Box>
          <Text  w={isDesktop ? "80%" : "95%"} p={isDesktop ? "0px 25px 50px 25px" : "0px"} align="start" fontSize="large">
            Dear Esteemed Partners, Throughout the years, we've tirelessly
            worked and persevered to carve a niche for ourselves in the garment
            industry. Through unwavering dedication, transparency, and
            continuous hard work, our goal has been to secure a prominent
            position within this dynamic sector. The consistent support from our
            valued buyers and sellers has propelled us to establish a 'Community
            of Reliability' spanning four cities today. With your continued
            support, we aspire to reach greater heights. excellence.
            <br />
            <br />
            Our path has been directed by a vision, unwavering values, and a
            dedication to excellence. This has nurtured a continuous
            conversation, offering support to both buyers and sellers at every
            stage. Observing our partners thrive in the garment industry brings
            us great satisfaction. This achievement is a collective effort, a
            testament to the dedication of our team and thought leaders who
            embody the essence of passion, purpose, and promise daily. Their
            unwavering commitment showcases our ideology: "WE STRIVE TO GET
            BETTER."
            <br />
            <br />
            Finally, I extend my heartfelt appreciation to all the people who
            gave their consistent cooperation and shared aspiration for growth.
            As we stand at the threshold of abundant opportunities, the future
            is ours to shape.
          </Text>
        </Stack>
      </VStack>
    </VStack>
  );
};
