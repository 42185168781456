import React from "react";
import { Navbar } from "./Navbar";
import { VStack, Text, HStack, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import { useGetProducts } from "../hooks/useGetProducts";
import { ProductItems } from "./ProductItems";

export const Products = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  const { menProducts, womenProducts, kidsProducts } = useGetProducts();
  return (
    <VStack>
      <Navbar currentIndex={2} />
      <VStack
        width="full"
        marginTop="100px"
        p={isDesktop ? "0px 25px 0px 25px" : "0px 9px 0px 9px"}
      >
        <Text
          w="full"
          align="center"
          fontSize="xxx-large"
          fontWeight="bold"
          color="red.700"
        >
          Product's Overview
        </Text>
        <Stack w="full" flexDirection={isDesktop ? "row" : "column"} justifyContent="space-around" overflow="hidden">
          <VStack w="full" h="66vh" bgColor="#e4e7e7">
            <Text
              align="center"
              w="full"
              fontWeight="bold"
              bgColor="red.700"
              color="white"
              fontSize="x-large"
            >
              WOMEN
            </Text>
              <ProductItems products={womenProducts} />
          </VStack>
          <VStack w="full" h="65vh" bgColor="#e4e7e7">
            <Text
              align="center"
              w="full"
              fontWeight="bold"
              bgColor="red.700"
              color="white"
              fontSize="x-large"
            >
              MEN
            </Text>
              <ProductItems products={menProducts} />
          </VStack>
          <VStack w="full" h="65vh" bgColor="#e4e7e7">
            <Text
              align="center"
              w="full"
              fontWeight="bold"
              bgColor="red.700"
              color="white"
              fontSize="x-large"
            >
              KIDS
            </Text>
              <ProductItems products={kidsProducts} />
          </VStack>
        </Stack>
      </VStack>
    </VStack>
  );
};
