import { useBreakpointValue } from '@chakra-ui/react'

const BreakpointValue = {
  "base": 0,
  "sm": 1,
  "md": 2,
  "lg": 3,
}

export const useCalculatedBreakpoints = () => {
  const breakpoint =
    useBreakpointValue(
      {
        base: BreakpointValue.base,
        sm: BreakpointValue.sm,
        md: BreakpointValue.md,
        lg: BreakpointValue.lg,
      },
      { ssr: false },
    ) ?? BreakpointValue.base

  return {
    isDesktop: breakpoint === BreakpointValue.lg,
    isTablet: breakpoint === BreakpointValue.md,
    isPhone: breakpoint === BreakpointValue.sm || breakpoint === BreakpointValue.base,
    isBase: breakpoint === BreakpointValue.base,
  }
}
