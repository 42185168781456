import React from "react";
import { Navbar } from "./Navbar";
import { VStack, Text, Img, Stack, HStack } from "@chakra-ui/react";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import officeLocation from "../assets/officeLocation.png";
import { Location } from "./Location";

export const Contact = () => {
  const { isDesktop } = useCalculatedBreakpoints();

  const locations = [
    {
      title: "Bengaluru",
      address:
        "1st floor, H no. 53, 2nd lane, 3rd cross, Lalbagh Road, Bengaluru, Karnataka, 560027",
    },
    {
      title: "Delhi (Gandhi Nagar & Tank Road)",
      address: "9/3, Ground floor, Geeta Colony, Delhi, 110031",
    },
    {
      title: "Kolkata",
      address: "Bara Bazar & Metiabruz, Kolkata, West Bengal, 700007",
    },
    {
      title: "Kanpur",
      address:
        "58/44, Opp. ICICI Bank, Birhana Road, Kanpur, Uttar Pradesh, 208001",
    },
  ];

  return (
    <VStack>
      <Navbar currentIndex={4} />
      <VStack
        display="flex"
        width="full"
        marginTop="100px"
        p={isDesktop ? "0px 25px 0px 25px" : "0px 9px 0px 9px"}
      >
        <Text
          w="full"
          align="center"
          fontSize="xxx-large"
          fontWeight="bold"
          color="red.700"
        >
          Our Presence
        </Text>
        <Img src={officeLocation} h={isDesktop ? "60vh" : "45vh"} />
        <Text
          w="full"
          align="left"
          fontSize="xx-large"
          fontWeight="bold"
          color="red.700"
        >
          Locations
        </Text>
        <Stack
          flexDirection={isDesktop ? "row" : "column"}
          w="full"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {locations.map((location, index) => {
            return <Location key={index} location={location} />;
          })}
        </Stack>
        <Stack flexDirection={isDesktop ? "row" : "column"} w="full" align="flex-start">
          <Text
            fontSize="xx-large"
            fontWeight="bold"
            color="red.700"
          >
            Contact Numbers:
          </Text>
          <Text paddingTop={isDesktop ? "14px" : "0px"}>+91-7233000710</Text>
          <Text paddingTop={isDesktop ? "14px" : "0px"}> +91-7233000883</Text>
        </Stack>
      </VStack>
    </VStack>
  );
};
