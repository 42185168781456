import React, { useState } from "react";
import { Tabs, Tab, TabList } from "@chakra-ui/react";

export const NavBarTabs = (props) => {
  const { navBarTabs, handleTabClick, currentIndex } = props;
  const [activeIndex, setActiveIndex] = useState(currentIndex);
  return (
    <Tabs index={activeIndex} colorScheme="red" color="red" variant="soft-rounded" onChange={(index) => setActiveIndex(index)}>
      <TabList>
        {navBarTabs.map((tab, index) => (
          <Tab index={index} key={`maa-ki-kripa-${index}`} onClick={() => handleTabClick(index)}>
            {tab.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};
