import React from "react";
import { Wrap, WrapItem } from "@chakra-ui/react";

export const ProductItems = (props) => {
  const { products } = props;
  return (
    <Wrap overflow="scroll">
      {Object.keys(products).map((category) => (
        <>
          <WrapItem
            key={category}
            align="start"
            w="full"
            fontWeight="bold"
            fontSize="large"
            p="10px"
          >
            {category}
          </WrapItem>
          {products[category].map((product) => (
            <WrapItem
              key={product.name}
              align="center"
              w="full"
              fontSize="large"
              p="0px 0px 0px 30px"
            >
              {product.name}
            </WrapItem>
          ))}
        </>
      ))}
    </Wrap>
  );
};
