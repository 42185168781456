export const useGetProducts = () => {
  const menProducts = {
    "Ethnic Wear": [
      { name: "Kurta Payjama" },
      { name: "Sherwanis" },
      { name: "Nehru Jackets" },
      { name: "Dhoti Kurta" },
    ],
    "TopWear": [
      { name: "T-Shirts" },
      { name: "Casual Shirts" },
      { name: "Formal Shirts" },
      { name: "SweatShirts" },
      {name: "Jackets"},
      {name: "Blazers"},
      {name: "Suits"},
      {name: "Sweaters"},
      {name: "Coats"},
      {name: "Rain Jackets"},
    ],
    "BottomWear": [
        {name: "Jeans"},
        {name: "Casual & Formal Trousers"},
        {name: "Shorts"},
        {name: "Track Pants"},
        {name: "Joggers"},
        {name: "Track Suits"},
    ]
  };

  const womenProducts = {
    "Ethnic Wear": [
        { name: "Salwar Suits" },
        { name: "Lehenga Choli" },
        { name: "Kurtis" },
        { name: "Gowns" },
        {name: "Tunics & Tops"},
        {name: "Ethnic Dresses"},
        {name: "Leggings, Salwars & Churidars"},
        {name: "Skirts & Plazzos"},
        {name: "Dress Material"},
        {name: "Dupattas & Stoles"},
        {name: "Unstitched Fabric"},
        ],
    "Western Wear": [
        { name: "Tops, T-Shirts & Shirts" },
        { name: "Dresses" },
        { name: "Jeans & Jeggings" },
        { name: "Trousers & Capris" },
        {name: "Shorts & Skirts"},
        {name: "Shrugs & Jackets"},
        {name: "Sweaters & Sweatshirts"},
        {name: "Coats & Blazers"},
        {name: "Jumpsuits & Rompers"},
        {name: "Cardigans & Pullovers"},
        {name: "Blazers & Waistcoats"},
        ],
    "Active Wear": [
        {name: "Track Suits & T-Shirts"},
        {name: "Track Pants & Shorts"},
        {name: "Rain Coats "},
        ],
  }

    const kidsProducts = {
        "Boys": [
            {name: "T-Shirts & Shirts"},
            {name: "Shorts & 3/4ths"},
            {name: "Jeans & Trousers"},
            {name: "Track Pants & Pyjamas"},
            {name: "Ethnic Wear"},
            {name: "Winter Wear"},
            {name: "Pull-overs & Sweaters"},
            {name: "Coats & Jackets"},
            {name: "Kurtas & Pyjamas"},
            {name: "Night Suits"},
            {name: "Coordinated Sets"},
            {name: "Blazers & Suits"},
            {name: "Track Suits"},
            {name: "Nehru Jackets"},
            {name: "Rain Jackets"},
        ],
        "Girls": [
            {name: "T-Shirts & Tops"},
            {name: "Dresses & Jumpsuits"},
            {name: "Skirts & Shorts"},
            {name: "Jeans & Trousers & Capris"},
            {name: "Ethic Wear"},
            {name: "Dungarees & Jumpsuits"},
            {name: "Jeggings & Leggings"},
            {name: "Coats & Jackets"},
            {name: "Sweaters & Sweatshirts"},
            {name: "Night Suits"},
            {name: "Coordinated Sets"},
        ],
        "Infants": [
            {name: "Romper & Onesies"},
            {name: "T-Shirts & Tops"},
            {name: "Dresses"},
            {name: "Bottomwear"},
            {name: "Winter Wear"},
        ],
    }

    return { menProducts, womenProducts, kidsProducts };
};
