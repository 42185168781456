import React from "react";
import { HStack, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";

export const Hamburger = (props) => {
  const { menuItems, handleMenuItemClick, currentIndex } = props;
  const { isBase } = useCalculatedBreakpoints();
  return (
    <HStack>
      <Menu>
        <MenuButton w={isBase ? 10 : 12} h={7} color="red.700" bg="transparent">
          <HamburgerIcon boxSize="30px" />
        </MenuButton>
        <MenuList zIndex={200} bgColor="red.50" maxH="30vh" overflowY="auto">
          {menuItems?.map((tab, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick(index);
              }}
              color={currentIndex === index ? "red.700" : "black"}
              bgColor={currentIndex === index ? "red.100" : "transparent"}
            >
              {tab.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
};
